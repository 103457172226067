import { SpeakerphoneIcon } from "@heroicons/react/outline";
import { useCookieConsentContext } from "@use-cookie-consent/react";

const CookieConsentBanner = () => {
  const { acceptAllCookies } = useCookieConsentContext();

  return (
    <>
      {/*
      Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
      your content from being obscured when the user scrolls to the bottom of the page.
    */}
      <div className="fixed inset-x-0 bottom-0">
        <div className="bg-secondary">
          <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center flex-1 w-0">
                <span className="flex p-2 rounded-lg bg-primary">
                  <SpeakerphoneIcon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">Allow cookies</span>
                  <span className="hidden md:inline">
                    This website uses 3rd party cookies to improve your
                    experience.
                  </span>
                </p>
              </div>
              <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                <button
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium bg-white border border-transparent rounded-md shadow-sm text-primary hover:bg-indigo-50"
                  onClick={acceptAllCookies}
                >
                  Accept Cookies
                </button>
              </div>
              <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
                <br />
                {/* <button
                  type="button"
                  className="flex p-2 -mr-1 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  // onClick={declineAllCookies}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsentBanner;
