import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Image from "next/future/image";
import Link from "next/link";
import { Fragment, useState } from "react";

import classNames from "../lib/classNames";
import { CommonProps } from "../lib/getCommonProps";
import Logo from "./../public/logo.png";
import BlurImage from "./BlurImage";
import ThreeColumnFlyoutMenu from "./menus/ThreeColumnFlyoutMenu";

type Props = CommonProps;

const Header = ({ commonCategories, commonPages }: Props) => {
  const flyoutMenus = commonCategories.filter(
    (category) => category.flyoutMenu
  );
  const [flyoutMenuState, setFlyoutMenuState] = useState(
    flyoutMenus.reduce(
      (acc, cur) => {
        acc[cur.id] = false;
        return acc;
      },
      { more: false } as Record<string, boolean>
    )
  );

  return (
    <>
      <Popover className="relative bg-white">
        <div className="flex items-center justify-between px-4 py-6 mx-auto sm:px-6 md:justify-start max-w-7xl md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <span className="sr-only">Welfare Abroad</span>
            <Link href="/">
              <Image
                className="w-48 h-16 cursor-pointer"
                src={Logo}
                placeholder="blur"
                alt="Welfare Abroad Logo"
                sizes="(max-width: 1200px) 15vw,
                        10vw"
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Link href={"/hair-transplant/fue-hair-transplant"} passHref>
              <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                Hair Transplant
              </a>
            </Link>
            {flyoutMenus.map((category) => (
              <ThreeColumnFlyoutMenu
                category={category}
                key={category.id}
                flyoutMenuState={flyoutMenuState}
                setFlyoutMenuState={setFlyoutMenuState}
              />
            ))}

            {commonPages.map(
              (page) =>
                page.rootMenu && (
                  <Link href={"/" + page.seo.slug} passHref key={page.id}>
                    <a className="text-base font-medium text-gray-500 hover:text-gray-900">
                      {page.title || page.seo.slug}
                    </a>
                  </Link>
                )
            )}

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    )}
                    onMouseEnter={() => setFlyoutMenuState({ more: true })}
                  >
                    <span>More</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    show={flyoutMenuState.more}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                      static
                      onMouseLeave={() => setFlyoutMenuState({ more: false })}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          {commonPages
                            .filter((page) => !page.excludeFromMenu)
                            .map(
                              (page) =>
                                !page.rootMenu && (
                                  <Link
                                    href={"/" + page.seo.slug}
                                    key={page.id}
                                    passHref
                                  >
                                    <a className="block p-3 -m-3 rounded-md hover:bg-gray-50">
                                      <p className="text-base font-medium text-gray-900">
                                        {page.highlightedTitlePrefix}{" "}
                                        {page.title}
                                      </p>
                                      {page.description && (
                                        <p className="mt-1 text-sm text-gray-500">
                                          {page.description
                                            .split(" ")
                                            .slice(0, 15)
                                            .join(" ")}
                                          ...
                                        </p>
                                      )}
                                    </a>
                                  </Link>
                                )
                            )}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="items-center justify-end hidden lg:flex md:flex-1 lg:w-0">
            <a
              href="#contact-us"
              className="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary whitespace-nowrap hover:bg-lime-500"
            >
              Get a Free Quote Now
            </a>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform md:hidden"
          >
            <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <BlurImage
                      className="w-auto h-16 rounded-xl"
                      src={Logo}
                      alt="Welfare Abroad Logo"
                      sizes="50vw"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="px-5 py-6">
                <div className="grid grid-cols-2 gap-4">
                  <Link href="/hair-transplant/fue-hair-transplant" passHref>
                    <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Hair Transplant
                    </a>
                  </Link>
                  {commonCategories
                    .filter((category) => category.flyoutMenu)
                    .map((category) => (
                      <Link href={category.seo.slug} key={category.id} passHref>
                        <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                          {category.name}
                        </a>
                      </Link>
                    ))}
                  {commonPages
                    .filter((page) => !page.excludeFromMenu)
                    .map((page) => (
                      <Link href={page.seo.slug} key={page.id} passHref>
                        <a className="text-base font-medium text-gray-900 hover:text-gray-700">
                          {page.highlightedTitlePrefix} {page.title}
                        </a>
                      </Link>
                    ))}
                </div>
                <div className="mt-6">
                  <Link href="#contact-us" passHref>
                    <a className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary hover:bg-indigo-700">
                      Get a Free Quote Now
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default Header;
