import { ExclamationIcon } from "@heroicons/react/outline";
import Link from "next/link";

const PreviewAlert = () => {
  return (
    <div className="bg-red-600">
      <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center flex-1 w-0">
            <span className="flex p-2 rounded-lg">
              <ExclamationIcon
                className="w-6 h-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">Preview Alert</span>
              <span className="hidden md:inline">
                You are currently in preview mode.
              </span>
            </p>
          </div>
          <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
            <Link href="/api/exit-preview" passHref>
              <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-50">
                Exit Preview Mode
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAlert;
