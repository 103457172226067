import Image from "next/future/image";
import Link from "next/link";

import { CommonProps } from "../lib/getCommonProps";
import Logo from "./../public/logo.png";
import SocialMediaIcons from "./SocialMediaIcons";

type Props = CommonProps;

const Footer = ({ commonPages }: Props) => {
  const footerLinks = [
    { title: "Hair Transplant Before After Gallery", href: "/before-after-gallery" },
    { title: "Hair Transplant Prices in Turkey and UK", href: "/hair-transplant-prices" },
    { title: "Our Blog Posts", href: "/blog" },
    { title: "Affiliate Program", href: "/affiliate-program" },
    { title: "How to Book Your Travel", href: "/how-to-book" },
    { title: "Meet with Our Team", href: "/our-team" },
    { title: "Our Services", href: "/services" },
    { title: "Frequently Asked Questions", href: "/faq" },
  ];

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:flex lg:justify-between lg:items-start">
          <div className="w-full lg:w-1/4">
            <Image
              className="w-auto h-16 mb-4"
              src={Logo}
              alt="Welfare Abroad"
              placeholder="blur"
              sizes="(max-width: 1200px) 100vw, 20vw"
            />
            <p className="text-base text-gray-500">
              <b>Treat yourself and choose Welfare Abroad.</b>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:w-3/4">
            <ul role="list" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4">
              {footerLinks.map((link, index) => (
                <li key={index}>
                  <Link href={link.href} passHref>
                    <a className="text-base text-gray-500 hover:text-gray-900">
                      {link.title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex justify-center mb-8">
            <SocialMediaIcons darker />
          </div>
          <p className="text-base text-gray-400 text-center">
            &copy; {new Date().getFullYear()} Welfare Abroad. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
