import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { Dispatch, Fragment, SetStateAction } from "react";

import classNames from "../../lib/classNames";
import { CommonProps } from "../../lib/getCommonProps";

type Props = {
  category: CommonProps["commonCategories"][0];
  flyoutMenuState: Record<string, boolean>;
  setFlyoutMenuState: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const ThreeColumnFlyoutMenu = ({
  category,
  flyoutMenuState,
  setFlyoutMenuState,
}: Props) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            )}
            onMouseEnter={() => setFlyoutMenuState({ [category.id]: true })}
            onClick={() => setFlyoutMenuState({ [category.id]: false })}
          >
            <Link href={"/" + category.seo.slug} passHref>
              <a>
                <span>{category.name}</span>
              </a>
            </Link>
            <ChevronDownIcon
              className={classNames(
                open ? "text-gray-600" : "text-gray-400",
                "ml-2 h-5 w-5 group-hover:text-gray-500"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            show={flyoutMenuState[category.id]}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classNames(
                category.thirdColumn ? "lg:max-w-5xl" : "lg:max-w-3xl",
                "absolute z-10 w-screen max-w-md mt-3 -ml-4 transform lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
              )}
              static
              onMouseLeave={() => setFlyoutMenuState({ [category.id]: false })}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div
                  className={classNames(
                    category.thirdColumn ? "lg:grid-cols-3" : "lg:grid-cols-2",
                    "relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8"
                  )}
                >
                  {category.treatments.map((treatment) => (
                    <Link
                      key={treatment.id}
                      href={
                        "/" +
                        treatment.categories
                          .map((category) => category.seo.slug)
                          .join("/") +
                        "/" +
                        treatment.seo.slug
                      }
                      passHref
                    >
                      <a className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                        {/* <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-indigo-500 rounded-md sm:h-12 sm:w-12">
                        <treatment.icon
                          className="w-6 h-6"
                          aria-hidden="true"
                        />
                      </div> */}
                        <div className="ml-4">
                          <p className="text-base font-medium text-gray-900">
                            {treatment.title}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            {treatment.description}
                          </p>
                        </div>
                      </a>
                    </Link>
                  ))}
                </div>
                <div className="p-5 bg-gray-50 sm:p-8">
                  <Link
                    href={"/plastic-surgeries-before-after-gallery"}
                    passHref
                  >
                    <a className="flow-root p-3 -m-3 rounded-md hover:bg-gray-100">
                      <div className="flex items-center">
                        <div className="text-base font-medium text-gray-900">
                          {category.name} Before After Gallery
                        </div>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">
                        Browse our before-after gallery for {category.name}
                      </p>
                    </a>
                  </Link>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ThreeColumnFlyoutMenu;
